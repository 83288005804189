import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IEsAlias } from 'src/types/User';

interface AlertState {
  esAlias?: IEsAlias;
}

const initialState: AlertState = {};

const AlertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    setEsAlias(state, action: PayloadAction<IEsAlias>) {
      state.esAlias = action.payload;
    },
  },
});

export const { setEsAlias } = AlertSlice.actions;
export default AlertSlice.reducer;
