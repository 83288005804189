import { SOURCE_TYPES } from './SourceModelTypes';
import { CHART_COMPARATOR_TYPES } from './ChartComparatorTypes';

const PERCENT_CHANGE_COMPARATOR_META = Object.freeze({
  [CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE]: {
    [SOURCE_TYPES.FCR]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.SURVEY_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.NPS_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.CSAT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.CSAT_EXPERIENCE]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.SURVEY_CSAT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.FCR_SAME_CALLER]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.ORIGINAL_CALLS]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.REPLY_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.REPLY_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.FULL_RESOLUTION_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.FULL_RESOLUTION_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.FIRST_RESOLUTION_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.FIRST_RESOLUTION_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.REQUESTER_WAIT_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.REQUESTER_WAIT_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.REPLIES]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.OVERALL_DISSATISFACTION]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.MEDIAN_TIME_TO_REPLY]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ABSOLUTE: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.TIME_TO_FIRST_CLOSE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.TIME_TO_LAST_CLOSE]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ABSOLUTE: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.COUNT_CONVERSATION_PARTS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.PERCENT_OF_SEGMENTS]: {
      IS_PERCENT_SYMBOL_NEED: false,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
    },
    [SOURCE_TYPES.PERCENT_OF_RESPONSES]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.CSAT_RATING]: {},
    [SOURCE_TYPES.AVERAGE_HANDLE_TIME]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.CUSTOMER_INTERACTION_TIME]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.AVERAGE_SENTIMENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CALLS_RESOLVED]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_VERIFICATION_DONE_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLOSING_ETIQUETTE_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_OPENING_ETIQUETTE_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_AUTOMATIC_FAILURE]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_LISTENING_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_UNDERSTANDING_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_KNOWLEDGE_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT_VALUE]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CONVERSATION_RESOLVED]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHY_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_FRIENDLINESS_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_AGENT_PERFORMANCE_QUALITY]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT_VALUE]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_POLITE_AGENT]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_POLITE_AGENT_VALUE]: {
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.ORDERS]: {
      IS_ADD_SYMBOL_NEED: true,
    },
  },
  [CHART_COMPARATOR_TYPES.PERCENT_TYPE]: {
    [SOURCE_TYPES.SURVEY_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.FCR]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.NPS_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.CSAT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.CSAT_EXPERIENCE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.SURVEY_CSAT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.FCR_SAME_CALLER]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.ORIGINAL_CALLS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.REPLY_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.REPLY_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.FULL_RESOLUTION_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.FULL_RESOLUTION_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.FIRST_RESOLUTION_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.FIRST_RESOLUTION_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.REQUESTER_WAIT_TIME_BUSINESS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.REQUESTER_WAIT_TIME_CALENDAR]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.REPLIES]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.OVERALL_DISSATISFACTION]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.MEDIAN_TIME_TO_REPLY]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.TIME_TO_FIRST_CLOSE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.TIME_TO_LAST_CLOSE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.COUNT_CONVERSATION_PARTS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_DECIMAL: false,
      CONVERT_MINS_TO_HOURS: false,
    },
    [SOURCE_TYPES.PERCENT_OF_SEGMENTS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.PERCENT_OF_RESPONSES]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
      IS_ABSOLUTE: false,
      IS_LOCALE_STRING: false,
      IS_ROUND_OFF: false,
    },
    [SOURCE_TYPES.CSAT_RATING]: {
      IS_DECIMAL: true,
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.AVERAGE_HANDLE_TIME]: {
      IS_ADD_SYMBOL_NEED: true,
      IS_PERCENT_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.CUSTOMER_INTERACTION_TIME]: {
      IS_ADD_SYMBOL_NEED: true,
      IS_PERCENT_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT]: {
      IS_ADD_SYMBOL_NEED: true,
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
      IS_ADD_SYMBOL_NEED: true,
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.AVERAGE_SENTIMENT]: {
      IS_ADD_SYMBOL_NEED: true,
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ROUND_OFF: true,
    },
    [SOURCE_TYPES.PERCENT_CALLS_RESOLVED]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_VERIFICATION_DONE_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLOSING_ETIQUETTE_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_OPENING_ETIQUETTE_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_AUTOMATIC_FAILURE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_LISTENING_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_UNDERSTANDING_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_KNOWLEDGE_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT_VALUE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_CONVERSATION_RESOLVED]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_EMPATHY_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_FRIENDLINESS_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_AGENT_PERFORMANCE_QUALITY]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },

    [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT_VALUE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_POLITE_AGENT]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.PERCENT_POLITE_AGENT_VALUE]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
    [SOURCE_TYPES.ORDERS]: {
      IS_PERCENT_SYMBOL_NEED: true,
      IS_ADD_SYMBOL_NEED: true,
    },
  },
});

const METRICS_VALUE_META = Object.freeze({
  [SOURCE_TYPES.FCR]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.SURVEY_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.PERCENT_POSITIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
    IS_PERCENT_SYMBOL_NEED: true,
  },
  [SOURCE_TYPES.NPS_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.CSAT_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.CSAT_EXPERIENCE]: {
    IS_PERCENT_SYMBOL_NEED: true,
  },
  [SOURCE_TYPES.SURVEY_CSAT_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.SUPPORT_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: true,
  },
  [SOURCE_TYPES.FCR_SAME_CALLER]: {
    IS_PERCENT_SYMBOL_NEED: true,
  },
  [SOURCE_TYPES.FCR_SAME_CALLER_SAME_REASON]: {
    IS_PERCENT_SYMBOL_NEED: true,
  },
  [SOURCE_TYPES.ORIGINAL_CALLS]: {
    IS_LOCALE_STRING: true,
  },
  [SOURCE_TYPES.CPO_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.CONVERSATIONS_PER_ORDER]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.OVERALL_DISSATISFACTION]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.REPLY_TIME_BUSINESS]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.REPLY_TIME_CALENDAR]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.FULL_RESOLUTION_TIME_BUSINESS]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.FULL_RESOLUTION_TIME_CALENDAR]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.FIRST_RESOLUTION_TIME_BUSINESS]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.FIRST_RESOLUTION_TIME_CALENDAR]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.REQUESTER_WAIT_TIME_BUSINESS]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.REQUESTER_WAIT_TIME_CALENDAR]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.MEDIAN_TIME_TO_REPLY]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: true,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.TIME_TO_FIRST_CLOSE]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: true,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.TIME_TO_LAST_CLOSE]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: true,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: true,
  },
  [SOURCE_TYPES.COUNT_CONVERSATION_PARTS]: {
    IS_PERCENT_SYMBOL_NEED: false,
    IS_ABSOLUTE: true,
    IS_LOCALE_STRING: true,
    IS_DECIMAL: false,
    CONVERT_MINS_TO_HOURS: false,
  },
  [SOURCE_TYPES.REPLIES]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: true,
    IS_DECIMAL: false,
  },
  [SOURCE_TYPES.PERCENT_OF_SEGMENTS]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_OF_RESPONSES]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.CSAT_RATING]: {
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.AVERAGE_HANDLE_TIME]: {
    IS_LOCALE_STRING: true,
    IS_SECONDS: true,
  },
  [SOURCE_TYPES.CUSTOMER_INTERACTION_TIME]: {
    IS_LOCALE_STRING: true,
    IS_SECONDS: true,
  },
  [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT]: {
    IS_ABSOLUTE: true,
  },
  [SOURCE_TYPES.PERCENT_NEGATIVE_SENTIMENT_INCLUDE_NEUTRAL]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_ABSOLUTE: false,
    IS_LOCALE_STRING: false,
  },
  [SOURCE_TYPES.PERCENT_CALLS_RESOLVED]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CUSTOMER_HAPPY_AT_END_OF_CALL_ANALYSIS]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_VERIFICATION_DONE_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CLOSING_ETIQUETTE_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_OPENING_ETIQUETTE_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_AUTOMATIC_FAILURE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_LISTENING_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CLIENT_FOCUSED_UNDERSTANDING_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_KNOWLEDGE_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_EMPATHETIC_AGENT_VALUE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_CONVERSATION_RESOLVED]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_EMPATHY_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_FRIENDLINESS_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONALISM_SHOWN_BY_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_AGENT_PERFORMANCE_QUALITY]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_PROFESSIONAL_AGENT_VALUE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_POLITE_AGENT]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.PERCENT_POLITE_AGENT_VALUE]: {
    IS_PERCENT_SYMBOL_NEED: true,
    IS_DECIMAL: true,
  },
  [SOURCE_TYPES.ORDERS]: {
    IS_LOCALE_STRING: true,
  },
});

export { PERCENT_CHANGE_COMPARATOR_META, METRICS_VALUE_META };
