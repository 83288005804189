import React, { FC, PropsWithChildren } from 'react';
import { RadixIconDropdownMenu, RadixIconDropdownMenuProps } from '../DropdownMenu/RadixDropdownMenu/RadixIconDropdownMenu';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

interface RadixIconDropdownProps extends PropsWithChildren<RadixIconDropdownMenuProps> {
  disabled?: boolean;
}

export const RadixIconDropdown: FC<RadixIconDropdownProps> = ({ children, disabled, ...rest }) => {
  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger disabled={disabled} asChild>
        {children}
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Portal>
        <RadixIconDropdownMenu {...rest} />
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
};
