import { Dashboard, FolderResponse } from 'src/types/dashboard';
import { CreateDashbaoardResponse, DashboardDataResponse, ReportOrderTypes } from '../apiTypes/Dashboard';
import { getAdminClientInstance, getEdgeFunctionClientInstance } from '../utils/clientAdapters';

export const getDashboards = async () => {
  try {
    const key = 'user/dashboards';
    return (await getAdminClientInstance(key).get<{ data: FolderResponse[] }>(key)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDashboardData = async (id: number) => {
  try {
    const key = 'dashboards/';
    const response = (await getAdminClientInstance('getDashboardData').get<DashboardDataResponse>(key + id)).data;
    response.data.calender_date = response.data.calender_date.map((data) => ({
      ...data,
      dateRange: { start: `${data.dateRange.start}Z`, end: `${data.dateRange.end}Z` },
    }));
    return response;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateDashboard = async (data: Dashboard & ReportOrderTypes) => {
  try {
    const key = 'dashboards/';
    return (await getAdminClientInstance('updateDashboard').patch(key + data.id, data)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const createDashboard = async (data: Omit<Partial<Dashboard>, 'id'>) => {
  try {
    const key = 'dashboards';
    return (await getAdminClientInstance('createDashboard').post<CreateDashbaoardResponse>(key, data)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteDashboard = async (id: number) => {
  try {
    const key = 'dashboards/';
    return (await getAdminClientInstance('deleteDashboard').delete(key + id)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const duplicateDashboard = async (id: number) => {
  try {
    const key = `dashboards/${id}/duplicate`;
    return (await getAdminClientInstance('duplicateDashboard').post(key)).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const setOrRemoveHomeDashboard = async (dashboardId: string | undefined, global: boolean) => {
  try {
    const key = 'home/dashboard';
    if (dashboardId) await getAdminClientInstance('setDashboardHomepage').post(key, { dashboard: dashboardId }, { params: { global } });
    else await getAdminClientInstance('removeDashboardHomepage').delete(key, { params: { global } });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const lockDashboard = async (dashboardId: number, rangeQuery: string) => {
  try {
    const key = `dashboard/lock-date-range`;
    return (await getEdgeFunctionClientInstance('lockDashboard').post(key, { dashboardId, rangeQuery })).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDashboardLock = async (dashboardId: number) => {
  try {
    const key = `dashboard/lock-date-range`;
    return (await getEdgeFunctionClientInstance('getDashboardLock').get<{ rangeQuery: string | null }>(key, { params: { dashboardId } })).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const unlockDashboard = async (dashboardId: number) => {
  try {
    const key = `dashboard/lock-date-range`;
    return (await getEdgeFunctionClientInstance('unlockDashboard').delete(key, { params: { dashboardId } })).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
