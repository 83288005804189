import React, { useCallback, useContext, useMemo } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { useAppDispatch } from 'src/core/store';
import { useFilterGroupContext } from '../DataSourceFilterContainer';
import { cloneDeep } from 'lodash';
import { RelationDropdown } from './RelationDropdown';
import { FilterOperations } from 'src/types/enums/Filter';
import { useIndivisualFilterContext } from './FilterGroup';
import { ValuesDropdown } from './FilterValues';
import { trackFilterEvent, TRACK_EVENT } from 'src/core/trackEvent';
import { getFilterFieldData } from 'src/api/Tranformers';
import { useFilterContext } from '../DataSourceFilters';
import { useQuery } from '@tanstack/react-query';
import { getMetaDataFilterOptions } from 'src/api/Filters';

const FilterFieldContext = React.createContext<(Omit<ReturnType<typeof getFilterFieldData>, 'loadValues'> & { loading: boolean }) | null>(null);

const simlarValueGroups = [
  [FilterOperations.is, FilterOperations.isNot],
  [FilterOperations.contains, FilterOperations.doesNotContains],
];

export const IndivisualFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { filter, filterIndex } = useIndivisualFilterContext();
  const { datasourceFilters, updateDataSourceFilters, filterState } = useFilterContext();
  const { dataSource, filterGroupIndex } = useFilterGroupContext();
  const filterField = useMemo(() => getFilterFieldData(filter, dataSource, filterState), [filter, dataSource, filterState]);
  const optionsQuery = useQuery({
    queryKey: ['getFilterOptions', filter, dataSource, filterState.filters?.source, filterState.filters?.dateRange.toString()],
    enabled: filterField?.loadValues,
    queryFn: () => getMetaDataFilterOptions(filter, dataSource, filterState),
  });
  const filterContextValue = useMemo(
    () => ({ ...filterField, loading: optionsQuery.isLoading, options: (filterField?.loadValues ? optionsQuery.data : filterField?.options) ?? [] }),
    [filterField, optionsQuery.isLoading, optionsQuery.data]
  );
  const trackFilterAction = (actionType: string) =>
    trackFilterEvent(TRACK_EVENT.FILTER_ACTION, {
      Data_Source: dataSource,
      Action_Type: actionType,
      Value: filterField?.displayName,
      Options: filter.values,
    });
  const handleRemoveFilter = useCallback(() => {
    const newDataSourceFilters = cloneDeep(datasourceFilters);
    newDataSourceFilters[dataSource][filterGroupIndex].conditionalFilters.splice(filterIndex, 1);
    if (newDataSourceFilters[dataSource][filterGroupIndex].conditionalFilters.length === 0)
      newDataSourceFilters[dataSource].splice(filterGroupIndex, 1);
    if (newDataSourceFilters[dataSource].length === 0) delete newDataSourceFilters[dataSource];
    dispatch(updateDataSourceFilters(newDataSourceFilters));
    trackFilterAction('removed');
  }, [datasourceFilters]);
  const updateOperation = useCallback(
    (operation: FilterOperations) => {
      const newDataSourceFilters = cloneDeep(datasourceFilters);
      const filter = newDataSourceFilters[dataSource][filterGroupIndex].conditionalFilters[filterIndex];
      const simlarValue = simlarValueGroups.some((group) => group.includes(filter.operation) && group.includes(operation));
      if (!simlarValue) filter.values = [];
      filter.operation = operation;
      trackFilterAction('updated operation');
      dispatch(updateDataSourceFilters(newDataSourceFilters));
    },
    [datasourceFilters]
  );
  if (!filterField) return null;
  return (
    <FilterFieldContext.Provider value={filterContextValue}>
      <div className={cn('group/filter flex bg-gray-650 text-xs', { 'rounded-l-md': !filterIndex })}>
        <span className="p-2 font-bold">{filterField.displayName}</span>
        <RelationDropdown
          value={filter.operation}
          options={filterField.fieldType.applicable_operations}
          removeFilter={handleRemoveFilter}
          onValueChange={updateOperation}
          placeholder="Select"
          className="p-2"
        />
        {filter.operation && <ValuesDropdown removeFilter={handleRemoveFilter} />}
      </div>
    </FilterFieldContext.Provider>
  );
};

export const useFilterFieldContext = () => {
  const context = useContext(FilterFieldContext);
  if (!context) throw new Error('useFilterFieldContext must be used within FilterFieldContext');
  return context;
};
