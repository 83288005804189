import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';
import {
  getDashboards,
  updateDashboard,
  deleteDashboard,
  createDashboard,
  setOrRemoveHomeDashboard,
  duplicateDashboard,
} from 'src/api/dashboard/Dashboard';
import { DashboardState } from './types/dashboard';
import { getPreviousDefaultDateRange, setPreveiousDateRangeOnDateRangeChange } from 'Constants/previousDateRangeIntervals';
import { IChartIntervalBreakDown } from 'src/types/Filter';
import { IDateFilterData } from './types/filter';
import { setBreakDownOnDateRangeChange } from 'Utils/constants/BreakDownOptions';
import getDateRangeIntervals, { setDateRangeOnDateFieldChange } from 'Utils/constants/DateRangeIntervals';
import { deleteReport, duplicateReport } from 'src/api/dashboard/Report';
import { DashboardDataResponse } from 'src/api/apiTypes/Dashboard';
import { createFolder, deleteFolder, renameFolder, updateDashboardOrder, updateFolderOrder } from 'src/api/dashboard/Folders';
import { RootState } from '../store';

export const getDashboardsThunk = createAsyncThunk('dashboard/getDashboardsData', getDashboards);
export const createDashboardThunk = createAsyncThunk('dashboard/createDashboardData', (folderId: number) =>
  createDashboard({ name: 'Untitled', folder: folderId })
);
export const updateDashboardThunk = createAsyncThunk('dashboard/updateDashboardData', updateDashboard);
export const duplicateDashboardThunk = createAsyncThunk('dashboard/duplicateDashboardData', duplicateDashboard);
export const deleteDashboardThunk = createAsyncThunk('dashboard/deleteDashboardData', deleteDashboard);
export const setOrRemoveDashboardHomeThunk = createAsyncThunk<void, { dashboardId: string; global: boolean }>(
  'dashboard/setOrRemoveHomeDashboard',
  ({ dashboardId, global }) => setOrRemoveHomeDashboard(dashboardId, global)
);
export const deleteReportThunk = createAsyncThunk('dashboard/deleteReport', deleteReport);
export const duplicateReportThunk = createAsyncThunk('duplicateReport', duplicateReport);

export const createFolderThunk = createAsyncThunk('dashboard/createFolder', createFolder);
export const renameFolderThunk = createAsyncThunk('dashboard/renameFolder', renameFolder);
export const deleteFolderThunk = createAsyncThunk('dashboard/deleteFolder', deleteFolder);
export const updateFolderOrderThunk = createAsyncThunk('dashboard/updateFolderOrder', updateFolderOrder);
export const updateDashboardOrderThunk = createAsyncThunk('dashboard/updateDashboardOrder', updateDashboardOrder);

const initialState: DashboardState = {
  fetchDashboardsStatus: 'idle',
  createDashboardStatus: 'idle',
  reports: [],
  chartIntervalBreakdown: 'day',
  sidebarExpanded: true,
  folders: [],
  folderOpenStates: {},
};

const DashbnoardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardState: (state, action: PayloadAction<Partial<DashboardState>>) => Object.assign(state, { ...state, ...action.payload }),
    updateChartIntervalBreakdown: (state, action: PayloadAction<IChartIntervalBreakDown>) => {
      state.chartIntervalBreakdown = action.payload;
    },
    updateFilterDateRange: (state, action: PayloadAction<IDateFilterData>) => {
      state.filters.dateRange = { start: action.payload.startDate, end: action.payload.endDate };
      state.filters.interval = action.payload.interval;
      state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
      state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.filters.dateRange, state.chartIntervalBreakdown);
    },
    updateChartPreviousComparisonIntervalSelector: (state, action: PayloadAction<IDateFilterData>) => {
      const { startDate, endDate, interval } = action.payload;
      state.filters.previousDateRange = { start: startDate, end: endDate };
      state.filters.previous_interval = interval;
    },
    updateSelectedDateField: (state, action: PayloadAction<string>) => {
      state.filters.selectedDateField = action.payload;
      state.filters.dateRange = setDateRangeOnDateFieldChange(state.dateRangeBounds, state.filters);
      state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
    },
    updateSidebarExpanded: (state, action: PayloadAction<boolean>) => {
      state.sidebarExpanded = action.payload;
    },
    setDashboardData: (state, action: PayloadAction<DashboardDataResponse & { defaultDateRange: string }>) => {
      state.reports = action.payload.data.reports;
      if (!action.payload.data.calender_date.length) return;
      state.dateRangeBounds = action.payload.data.calender_date.map(({ dateRange, dateField }) => ({ ...dateField, ...dateRange }));
      if (!!state.filters) return;
      const dateRanges = getDateRangeIntervals(state.dateRangeBounds, state.dateRangeBounds[0].date_field);
      const selectedDateRange = dateRanges.find((dateRange) => dateRange.key === (action.payload.defaultDateRange || 'last7days'));
      state.filters = {
        selectedDateField: state.dateRangeBounds[0].date_field,
        dateRange: { start: selectedDateRange?.startDate, end: selectedDateRange?.endDate },
        interval: selectedDateRange?.key,
        previous_interval: 'default',
      };
      state.filters.previousDateRange = getPreviousDefaultDateRange(state.filters.dateRange);
      state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.filters.dateRange);
    },
    setFolderOpenState: (state, action: PayloadAction<{ folderId: string; isOpen: boolean }>) => {
      state.folderOpenStates[action.payload.folderId] = action.payload.isOpen;
    },
    setDashboardLocked: (state, action: PayloadAction<string>) => {
      state.lockedQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardsThunk.pending, (state) => {
        state.fetchDashboardsStatus = 'pending';
      })
      .addCase(getDashboardsThunk.fulfilled, (state, action) => {
        state.folders = action.payload.data.map((folder) => {
          /* 
          Here we have created a new ID called dragId for both folders and dashboards. 
          This helps us differentiate whether a dashboard or a folder is being dragged.
          */
          const updatedFolder = { ...folder, dragId: `folder-${folder.id}` };
          updatedFolder.dashboards = updatedFolder.dashboards?.map((dashboard) => ({ ...dashboard, dragId: `dashboard-${dashboard.id}` }));
          return updatedFolder;
        });
        state.fetchDashboardsStatus = 'fulfilled';
      })
      .addCase(getDashboardsThunk.rejected, (state) => {
        state.fetchDashboardsStatus = 'rejected';
      })
      .addCase(createDashboardThunk.pending, (state) => {
        state.createDashboardStatus = 'pending';
      })
      .addCase(createDashboardThunk.fulfilled, (state, action) => {
        state.createDashboardStatus = 'fulfilled';
        const folder = state.folders.find((folder) => folder.id === action.meta.arg);
        folder.dashboards.push({ ...action.payload.data, dragId: `dashboard-${action.payload.data.id}` });
      })
      .addCase(createDashboardThunk.rejected, (state) => {
        state.createDashboardStatus = 'rejected';
      })
      .addCase(updateDashboardThunk.pending, (state, action) => {
        const { id, name, description, report_order } = action.meta.arg;
        const dashboard = state.folders.flatMap((folder) => folder.dashboards).find((dashboard) => dashboard.id === id);
        if (dashboard) {
          dashboard.name = name ?? dashboard.name;
          dashboard.description = description ?? dashboard.description;
        }
        if (!report_order) return;
        const reportLookup = new Map(state.reports.map((report) => [report.id, report]));
        const reportsData = report_order.map((id) => reportLookup.get(id)).filter(Boolean);
        state.reports = reportsData;
      })
      .addCase(updateDashboardThunk.fulfilled, (state, action) => {
        const { id } = action.meta.arg;
        const dashboard = state.folders.flatMap((folder) => folder.dashboards).find((dashboard) => dashboard.id === id);
        if (dashboard) {
          dashboard.updated_at = action.payload.data.updated_at;
          dashboard.updated_by = action.payload.data.updated_by;
        }
      })
      .addCase(deleteDashboardThunk.fulfilled, (state, action) => {
        state.folders = state.folders.map((folder) => {
          folder.dashboards = folder.dashboards.filter((dashboard) => dashboard.id !== action.meta.arg);
          return folder;
        });
      })
      .addCase(duplicateDashboardThunk.fulfilled, (state, action) => {
        const folderIndex = state.folders.findIndex((folder) => folder.dashboards.some((dashboard) => dashboard.id === action.meta.arg));
        if (folderIndex === -1) return;
        const dashboardIndex = state.folders[folderIndex].dashboards.findIndex((dashboard) => dashboard.id === action.meta.arg);
        const newDashboard = {
          ...state.folders[folderIndex].dashboards[dashboardIndex],
          id: action.payload.id,
          name: action.payload.name,
          created_at: action.payload.created_at,
          updated_at: action.payload.updated_at,
          created_by: action.payload.created_by,
          update_by: action.payload.updated_by,
        };
        state.folders[folderIndex].dashboards.splice(dashboardIndex + 1, 0, { ...newDashboard, dragId: `dashboard-${newDashboard.id}` });
      })
      .addCase(duplicateReportThunk.fulfilled, (state, action) => {
        const duplicatedReportIndex = state.reports.findIndex((report) => report.id === action.meta.arg);
        const newReport = { ...state.reports[duplicatedReportIndex], id: action.payload.id, name: action.payload.name };
        state.reports.splice(duplicatedReportIndex + 1, 0, newReport);
      })
      .addCase(deleteReportThunk.fulfilled, (state, action) => {
        state.reports = state.reports.filter((report) => report.id !== action.meta.arg);
      })
      .addCase(createFolderThunk.fulfilled, (state, action) => {
        state.folders.push({ ...action.payload.data, dragId: `folder-${action.payload.data.id}` });
      })
      .addCase(renameFolderThunk.pending, (state, action) => {
        const { id, name } = action.meta.arg;
        const index = state.folders.findIndex((folder) => folder.id === id);
        if (index === -1) return;
        state.folders[index].name = name;
      })
      .addCase(deleteFolderThunk.fulfilled, (state, action) => {
        state.folders = state.folders.filter((folder) => folder.id !== action.meta.arg);
      })
      .addCase(updateFolderOrderThunk.pending, (state, action) => {
        const folderLookup = new Map(state.folders.map((folder) => [folder.id, folder]));
        const foldersData = action.meta.arg.map((id) => folderLookup.get(id));
        state.folders = foldersData;
      })
      .addCase(updateDashboardOrderThunk.pending, (state, action) => {
        const newDashboardOrders = action.meta.arg;
        const dashboardMap = new Map();
        state.folders.forEach((folder) => folder.dashboards.forEach((dashboard) => dashboardMap.set(dashboard.id, dashboard)));
        state.folders.forEach((folder, index) => {
          state.folders[index].dashboards = newDashboardOrders[folder.id]?.map((id) => dashboardMap.get(id)) || folder.dashboards;
        });
      });
  },
});

export const {
  updateChartIntervalBreakdown,
  updateFilterDateRange,
  updateChartPreviousComparisonIntervalSelector,
  updateSelectedDateField,
  updateSidebarExpanded,
  setDashboardData,
  setDashboardState,
  setFolderOpenState,
  setDashboardLocked,
} = DashbnoardSlice.actions;

const persistConfig = {
  key: 'dashboard',
  storage,
  whitelist: ['sidebarExpanded', 'folderOpenStates'],
};

export default persistReducer(persistConfig, DashbnoardSlice.reducer);

export const getAllDashboards = (state: RootState) => state.dashboard.folders.flatMap((folder) => folder.dashboards);
