export enum INTEGRATION_TYPES {
  ZENDESK = 'zendesk',
  ZENDESK_CHAT = 'zendesk_chat',
  ZENDESK_CSAT = 'zendesk_csat',
  SURVEY_MONKEY = 'survey_monkey',
  GORGIAS = 'gorgias',
  DIXA = 'dixa',
  FACEBOOK = 'facebook',
  INTERCOM = 'intercom',
  HELPSCOUT = 'helpscout',
  USABILLA = 'usabilla',
  TRUSTPILOT = 'trustpilot',
  GOOGLE_PLAYSTORE = 'google_playstore',
  APPLE_APPSTORE = 'apple_appstore',
  TYPEFORM = 'typeform',
  FRESHDESK = 'freshdesk',
  FRESHCHAT = 'freshchat',
  GENERIC = 'generics',
  SALESFORCE = 'salesforce',
}

export enum REVOKE_INTEGRATION_ENDPOINTS {
  USABILLA = 'integration/usabilla/revoke',
  TRUSTPILOT = 'integration/trustpilot/revoke',
  HELPSCOUT = 'integration/helpscout/revoke',
  DIXA = 'integration/dixa/revoke',
  FACEBOOK = 'integration/facebook/revoke',
  ZENDESK_CHAT = 'integration/zendesk-chat/revoke',
  SURVEY_MONKEY = 'integration/survey-monkey/revoke',
  GORGIAS = 'integration/gorgias/revoke',
  INTERCOM = 'integration/intercom/revoke',
  ZENDESK_CSAT = 'integration/zendesk/revoke?type=csat',
  ZENDESK = 'integration/zendesk/revoke',
  GOOGLE_PLAYSTORE = 'integration/google-play-store/revoke',
  APPLE_APPSTORE = 'integration/apple-app-store/revoke',
  TYPEFORM = 'integration/typeform/revoke',
  FRESHDESK = 'integration/freshdesk/revoke',
  FRESHCHAT = 'integration/freshchat/revoke',
  SALESFORCE = 'integration/salesforce/revoke',
}

export enum INTEGRATION_ACTIONS {
  GRANT = 'grant',
  REVOKE = 'revoke',
}

export const integrationSources = {
  [INTEGRATION_TYPES.ZENDESK_CSAT]: {
    key: INTEGRATION_TYPES.ZENDESK_CSAT,
    label: 'Zendesk CSAT',
  },
  [INTEGRATION_TYPES.ZENDESK]: {
    key: INTEGRATION_TYPES.ZENDESK,
    label: 'Zendesk Support',
  },
  [INTEGRATION_TYPES.ZENDESK_CHAT]: {
    key: INTEGRATION_TYPES.ZENDESK_CHAT,
    label: 'Zendesk Chat',
  },
  [INTEGRATION_TYPES.INTERCOM]: {
    key: INTEGRATION_TYPES.INTERCOM,
    label: 'Intercom',
  },
  [INTEGRATION_TYPES.SURVEY_MONKEY]: {
    key: INTEGRATION_TYPES.SURVEY_MONKEY,
    label: 'SurveyMonkey',
  },
  [INTEGRATION_TYPES.GORGIAS]: {
    key: INTEGRATION_TYPES.GORGIAS,
    label: 'Gorgias',
  },
  [INTEGRATION_TYPES.DIXA]: {
    key: INTEGRATION_TYPES.DIXA,
    label: 'Dixa',
  },
  [INTEGRATION_TYPES.FACEBOOK]: {
    key: INTEGRATION_TYPES.FACEBOOK,
    label: 'Facebook',
  },
  [INTEGRATION_TYPES.HELPSCOUT]: {
    key: INTEGRATION_TYPES.HELPSCOUT,
    label: 'Help Scout',
  },
  [INTEGRATION_TYPES.USABILLA]: {
    key: INTEGRATION_TYPES.USABILLA,
    label: 'Usabilla',
  },
  [INTEGRATION_TYPES.TRUSTPILOT]: {
    key: INTEGRATION_TYPES.TRUSTPILOT,
    label: 'Trustpilot',
  },
  [INTEGRATION_TYPES.GOOGLE_PLAYSTORE]: {
    key: INTEGRATION_TYPES.GOOGLE_PLAYSTORE,
    label: 'Google Play Store',
  },
  [INTEGRATION_TYPES.APPLE_APPSTORE]: {
    key: INTEGRATION_TYPES.APPLE_APPSTORE,
    label: 'Apple App Store',
  },
  [INTEGRATION_TYPES.TYPEFORM]: {
    key: INTEGRATION_TYPES.TYPEFORM,
    label: 'Typeform',
  },
  [INTEGRATION_TYPES.FRESHDESK]: {
    key: INTEGRATION_TYPES.FRESHDESK,
    label: 'Freshdesk',
  },
  [INTEGRATION_TYPES.FRESHCHAT]: {
    key: INTEGRATION_TYPES.FRESHCHAT,
    label: 'Freshchat',
  },
  [INTEGRATION_TYPES.SALESFORCE]: {
    key: INTEGRATION_TYPES.SALESFORCE,
    label: 'Salesforce',
  },
  [INTEGRATION_TYPES.GENERIC]: {
    key: INTEGRATION_TYPES.GENERIC,
    label: 'Custom Integration',
  },
} as const;
